import {useUserStore} from "~/stores/user"

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('consent-module', {
        mounted (el) {
            const userStore = useUserStore()
            if ((!userStore.userData.features.consentUrlPrefix || userStore.userData.features.consentUrlPrefix === '') && !userStore.userData.features.disableOutgoingLinks) {
                return
            }

            const matches = el.querySelectorAll("a")

            matches.forEach(($anchor: HTMLAnchorElement) => {
                let url = new URL($anchor.href)
                let matchHost = url.hostname
                const mustConsent = $anchor.dataset.hasOwnProperty('mustConsent')
                if (userStore.userData.features.consentUrlPrefix && (window.location.hostname !== matchHost || mustConsent)) {
                    if (userStore.userData.features.disableOutgoingLinks && !mustConsent) {
                        $anchor.innerHTML += ` (${$anchor.href})`
                        $anchor.removeAttribute('href')
                    } else {
                        $anchor.href = userStore.userData.features.consentUrlPrefix + $anchor.href
                        $anchor.target = '_blank'
                    }
                }
            })
        },
    })
})
